import * as Sentry from "@sentry/vue";

async function lazyLoadSentryIntegrations() {
  // don't load on server
  if (!import.meta.client) return [];

  const { replayIntegration } = await import("@sentry/vue");
  Sentry.addIntegration(
    replayIntegration({
      maskAllText: false,
      blockAllMedia: false,
    })
  );
}

function getSentryIntegrations() {
  // don't load on server
  if (!import.meta.client) return [];

  const router = useRouter();
  return [
    Sentry.browserTracingIntegration({ router }),
    Sentry.browserProfilingIntegration(),
  ];
}

export default defineNuxtPlugin({
  name: "sentry",
  parallel: true,
  async setup(nuxtApp) {
    const vueApp = nuxtApp.vueApp;
    const config = useRuntimeConfig();
    const sentry = config.public.sentry as {
      dsn: string;
      environment: string;
    };
    if (!sentry.dsn || import.meta.dev) {
      // don't load on dev or if not configured
      return;
    }
    Sentry.init({
      app: vueApp,
      dsn: sentry.dsn,
      environment: sentry.environment,
      integrations: getSentryIntegrations(),
      enabled: !import.meta.dev,
      // Set tracesSampleRate to 1.0 to capture 100%
      // of transactions for performance monitoring.
      // We recommend adjusting this value in production
      tracesSampleRate: 1.0,
      tracePropagationTargets: [
        "localhost",
        "https://cms.take10media.com",
        "https://wip.take10media.com",
      ],
      // Session Replay
      replaysSessionSampleRate: 1.0, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
      replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
      // Profiling
      profilesSampleRate: 1.0, // Profile 100% of the transactions. This value is relative to tracesSampleRate
    });

    // Lazy-load the replay integration to reduce bundle size
    lazyLoadSentryIntegrations();
  },
});
