import revive_payload_client_ii066WofsI from "/opt/buildhome/repo/node_modules/.pnpm/nuxt@3.13.1_@parcel+watcher@2.4.1_@types+node@22.5.5_better-sqlite3@11.3.0_drizzle-orm@0.33.0_bpuw7m4lfsq2xgxwkos7pjindi/node_modules/nuxt/dist/app/plugins/revive-payload.client.js";
import unhead_lBFDHYCW63 from "/opt/buildhome/repo/node_modules/.pnpm/nuxt@3.13.1_@parcel+watcher@2.4.1_@types+node@22.5.5_better-sqlite3@11.3.0_drizzle-orm@0.33.0_bpuw7m4lfsq2xgxwkos7pjindi/node_modules/nuxt/dist/head/runtime/plugins/unhead.js";
import router_yWqie2RAUO from "/opt/buildhome/repo/node_modules/.pnpm/nuxt@3.13.1_@parcel+watcher@2.4.1_@types+node@22.5.5_better-sqlite3@11.3.0_drizzle-orm@0.33.0_bpuw7m4lfsq2xgxwkos7pjindi/node_modules/nuxt/dist/pages/runtime/plugins/router.js";
import payload_client_e7SZLyiavC from "/opt/buildhome/repo/node_modules/.pnpm/nuxt@3.13.1_@parcel+watcher@2.4.1_@types+node@22.5.5_better-sqlite3@11.3.0_drizzle-orm@0.33.0_bpuw7m4lfsq2xgxwkos7pjindi/node_modules/nuxt/dist/app/plugins/payload.client.js";
import navigation_repaint_client_hYrKHnuYqQ from "/opt/buildhome/repo/node_modules/.pnpm/nuxt@3.13.1_@parcel+watcher@2.4.1_@types+node@22.5.5_better-sqlite3@11.3.0_drizzle-orm@0.33.0_bpuw7m4lfsq2xgxwkos7pjindi/node_modules/nuxt/dist/app/plugins/navigation-repaint.client.js";
import check_outdated_build_client_gaALClHPhw from "/opt/buildhome/repo/node_modules/.pnpm/nuxt@3.13.1_@parcel+watcher@2.4.1_@types+node@22.5.5_better-sqlite3@11.3.0_drizzle-orm@0.33.0_bpuw7m4lfsq2xgxwkos7pjindi/node_modules/nuxt/dist/app/plugins/check-outdated-build.client.js";
import chunk_reload_client_sryX33xyJd from "/opt/buildhome/repo/node_modules/.pnpm/nuxt@3.13.1_@parcel+watcher@2.4.1_@types+node@22.5.5_better-sqlite3@11.3.0_drizzle-orm@0.33.0_bpuw7m4lfsq2xgxwkos7pjindi/node_modules/nuxt/dist/app/plugins/chunk-reload.client.js";
import components_plugin_KR1HBZs4kY from "/opt/buildhome/repo/.nuxt/components.plugin.mjs";
import prefetch_client_9vl6uKYa2m from "/opt/buildhome/repo/node_modules/.pnpm/nuxt@3.13.1_@parcel+watcher@2.4.1_@types+node@22.5.5_better-sqlite3@11.3.0_drizzle-orm@0.33.0_bpuw7m4lfsq2xgxwkos7pjindi/node_modules/nuxt/dist/pages/runtime/plugins/prefetch.client.js";
import plugin_2WoZQ4IrH6 from "/opt/buildhome/repo/node_modules/.pnpm/nuxt-echarts@0.2.3_echarts@5.5.1_magicast@0.3.5_rollup@4.21.3_webpack-sources@3.2.3/node_modules/nuxt-echarts/dist/runtime/plugin.js";
import primevue_plugin_egKpok8Auk from "/opt/buildhome/repo/.nuxt/primevue-plugin.mjs";
import plugin_client_QQZOUPW2KR from "/opt/buildhome/repo/node_modules/.pnpm/@primevue+nuxt-module@4.0.7_@babel+parser@7.25.6_magicast@0.3.5_rollup@4.21.3_vue@3.5.5_webpack-sources@3.2.3/node_modules/@primevue/nuxt-module/dist/runtime/plugin.client.js";
import sentryClientPlugin_uL6qP3brrI from "/opt/buildhome/repo/plugins/sentryClientPlugin.ts";
export default [
  revive_payload_client_ii066WofsI,
  unhead_lBFDHYCW63,
  router_yWqie2RAUO,
  payload_client_e7SZLyiavC,
  navigation_repaint_client_hYrKHnuYqQ,
  check_outdated_build_client_gaALClHPhw,
  chunk_reload_client_sryX33xyJd,
  components_plugin_KR1HBZs4kY,
  prefetch_client_9vl6uKYa2m,
  plugin_2WoZQ4IrH6,
  primevue_plugin_egKpok8Auk,
  plugin_client_QQZOUPW2KR,
  sentryClientPlugin_uL6qP3brrI
]