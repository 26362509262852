<script setup lang="ts">

useHead({
  titleTemplate: (titleChunk) => {
    return titleChunk ? `Take 10 Media CMS - ${titleChunk}` : 'Take 10 Media CMS'
  }
})
</script>
<template>
  <Html>

  <Body class="m-0 p-0 flex flex-col min-h-screen bg-gray-100">
    <NuxtRouteAnnouncer />
    <NuxtLoadingIndicator />
    <NuxtLayout>
      <NuxtPage />
    </NuxtLayout>
  </Body>

  </Html>
</template>
