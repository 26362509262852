import { default as index3FRFjbvafbMeta } from "/opt/buildhome/repo/pages/adagency/ads/index.vue?macro=true";
import { default as createlGnafYJaSsMeta } from "/opt/buildhome/repo/pages/adagency/campaigns/create.vue?macro=true";
import { default as indexMApblWBP5BMeta } from "/opt/buildhome/repo/pages/adagency/campaigns/index.vue?macro=true";
import { default as indexV7PVDMjJw9Meta } from "/opt/buildhome/repo/pages/adagency/index.vue?macro=true";
import { default as _91id_93lapU7Hid6WMeta } from "/opt/buildhome/repo/pages/adagency/locations/[id].vue?macro=true";
import { default as indexxEk1WFUfzFMeta } from "/opt/buildhome/repo/pages/adagency/locations/index.vue?macro=true";
import { default as adagencyHIiaBOL9QXMeta } from "/opt/buildhome/repo/pages/adagency.vue?macro=true";
import { default as indexs0O24OzYbpMeta } from "/opt/buildhome/repo/pages/admin/adcampaigns/index.vue?macro=true";
import { default as _91id_939nFtahaIjoMeta } from "/opt/buildhome/repo/pages/admin/devices/[id].vue?macro=true";
import { default as indexCuYRFp9MJyMeta } from "/opt/buildhome/repo/pages/admin/devices/index.vue?macro=true";
import { default as indexhyMXUmjJA7Meta } from "/opt/buildhome/repo/pages/admin/index.vue?macro=true";
import { default as _91id_935ouxjHPze1Meta } from "/opt/buildhome/repo/pages/admin/installations/[id].vue?macro=true";
import { default as _91id_93EBdfQQIQ8JMeta } from "/opt/buildhome/repo/pages/admin/installations/copy/[id].vue?macro=true";
import { default as createmrqQbq09jmMeta } from "/opt/buildhome/repo/pages/admin/installations/create.vue?macro=true";
import { default as index65blP15mINMeta } from "/opt/buildhome/repo/pages/admin/installations/index.vue?macro=true";
import { default as reportsfdnX1mZlSEMeta } from "/opt/buildhome/repo/pages/admin/installations/reports.vue?macro=true";
import { default as _91id_93M7FORm7vXKMeta } from "/opt/buildhome/repo/pages/admin/locations/[id].vue?macro=true";
import { default as createCHhgSS4WK9Meta } from "/opt/buildhome/repo/pages/admin/locations/create.vue?macro=true";
import { default as indexryoePHhLhUMeta } from "/opt/buildhome/repo/pages/admin/locations/index.vue?macro=true";
import { default as index8mjJwSzZYFMeta } from "/opt/buildhome/repo/pages/admin/profile/index.vue?macro=true";
import { default as indexWGBwIiVTtWMeta } from "/opt/buildhome/repo/pages/admin/shipping/index.vue?macro=true";
import { default as _91id_93qQW7PBCtvCMeta } from "/opt/buildhome/repo/pages/admin/users/[id].vue?macro=true";
import { default as indexXtUngUp57YMeta } from "/opt/buildhome/repo/pages/admin/users/index.vue?macro=true";
import { default as admin02XFd05CPxMeta } from "/opt/buildhome/repo/pages/admin.vue?macro=true";
import { default as changepasswordXnpRbQuJQlMeta } from "/opt/buildhome/repo/pages/changepassword.vue?macro=true";
import { default as forgotpassword02IPaVZWWrMeta } from "/opt/buildhome/repo/pages/forgotpassword.vue?macro=true";
import { default as indexniDiYCWjuTMeta } from "/opt/buildhome/repo/pages/index.vue?macro=true";
import { default as indexZXsxW4mO7RMeta } from "/opt/buildhome/repo/pages/installer/index.vue?macro=true";
import { default as indexqFew3HK3lHMeta } from "/opt/buildhome/repo/pages/installer/profile/index.vue?macro=true";
import { default as _91id_93GjkUoiNppNMeta } from "/opt/buildhome/repo/pages/installer/task/[id].vue?macro=true";
import { default as installern1ozRyAz2mMeta } from "/opt/buildhome/repo/pages/installer.vue?macro=true";
import { default as utilsp2OkYUDiBUMeta } from "/opt/buildhome/repo/pages/lib/utils.ts?macro=true";
import { default as index4DUFzf1NpeMeta } from "/opt/buildhome/repo/pages/locationowner/ads/index.vue?macro=true";
import { default as createLTNXN1B3m2Meta } from "/opt/buildhome/repo/pages/locationowner/campaigns/create.vue?macro=true";
import { default as index9aYsnyDH7EMeta } from "/opt/buildhome/repo/pages/locationowner/campaigns/index.vue?macro=true";
import { default as indexy4Of6iioYOMeta } from "/opt/buildhome/repo/pages/locationowner/index.vue?macro=true";
import { default as _91id_930hyxbkeoffMeta } from "/opt/buildhome/repo/pages/locationowner/locations/[id].vue?macro=true";
import { default as indexpGSUko33I6Meta } from "/opt/buildhome/repo/pages/locationowner/locations/index.vue?macro=true";
import { default as indexhZeSFVxxOqMeta } from "/opt/buildhome/repo/pages/locationowner/profile/index.vue?macro=true";
import { default as locationownerjtiVyh8TyEMeta } from "/opt/buildhome/repo/pages/locationowner.vue?macro=true";
import { default as login4dfr6kRqBoMeta } from "/opt/buildhome/repo/pages/login.vue?macro=true";
import { default as index1RDTFNrpZoMeta } from "/opt/buildhome/repo/pages/operator/ads/index.vue?macro=true";
import { default as createvk03Pua4b4Meta } from "/opt/buildhome/repo/pages/operator/campaigns/create.vue?macro=true";
import { default as indexXHLTLoIA9rMeta } from "/opt/buildhome/repo/pages/operator/campaigns/index.vue?macro=true";
import { default as indexaGT12yHdMyMeta } from "/opt/buildhome/repo/pages/operator/index.vue?macro=true";
import { default as _91id_93MCGstgN4eOMeta } from "/opt/buildhome/repo/pages/operator/locations/[id].vue?macro=true";
import { default as indexix7q1D4zDQMeta } from "/opt/buildhome/repo/pages/operator/locations/index.vue?macro=true";
import { default as indexvbez9L2WFnMeta } from "/opt/buildhome/repo/pages/operator/profile/index.vue?macro=true";
import { default as operatorUDg64585YbMeta } from "/opt/buildhome/repo/pages/operator.vue?macro=true";
import { default as registerynsVf0yvMzMeta } from "/opt/buildhome/repo/pages/register.vue?macro=true";
import { default as _91id_9337AbSHTDUTMeta } from "/opt/buildhome/repo/pages/resetpassword/[id].vue?macro=true";
import { default as indexExi0T6aS0fMeta } from "/opt/buildhome/repo/pages/resetpassword/index.vue?macro=true";
export default [
  {
    name: adagencyHIiaBOL9QXMeta?.name,
    path: "/adagency",
    component: () => import("/opt/buildhome/repo/pages/adagency.vue").then(m => m.default || m),
    children: [
  {
    name: "adagency-ads",
    path: "ads",
    component: () => import("/opt/buildhome/repo/pages/adagency/ads/index.vue").then(m => m.default || m)
  },
  {
    name: "adagency-campaigns-create",
    path: "campaigns/create",
    component: () => import("/opt/buildhome/repo/pages/adagency/campaigns/create.vue").then(m => m.default || m)
  },
  {
    name: "adagency-campaigns",
    path: "campaigns",
    component: () => import("/opt/buildhome/repo/pages/adagency/campaigns/index.vue").then(m => m.default || m)
  },
  {
    name: "adagency",
    path: "",
    component: () => import("/opt/buildhome/repo/pages/adagency/index.vue").then(m => m.default || m)
  },
  {
    name: "adagency-locations-id",
    path: "locations/:id()",
    component: () => import("/opt/buildhome/repo/pages/adagency/locations/[id].vue").then(m => m.default || m)
  },
  {
    name: "adagency-locations",
    path: "locations",
    component: () => import("/opt/buildhome/repo/pages/adagency/locations/index.vue").then(m => m.default || m)
  }
]
  },
  {
    name: admin02XFd05CPxMeta?.name,
    path: "/admin",
    component: () => import("/opt/buildhome/repo/pages/admin.vue").then(m => m.default || m),
    children: [
  {
    name: "admin-adcampaigns",
    path: "adcampaigns",
    component: () => import("/opt/buildhome/repo/pages/admin/adcampaigns/index.vue").then(m => m.default || m)
  },
  {
    name: "admin-devices-id",
    path: "devices/:id()",
    component: () => import("/opt/buildhome/repo/pages/admin/devices/[id].vue").then(m => m.default || m)
  },
  {
    name: "admin-devices",
    path: "devices",
    component: () => import("/opt/buildhome/repo/pages/admin/devices/index.vue").then(m => m.default || m)
  },
  {
    name: "admin",
    path: "",
    component: () => import("/opt/buildhome/repo/pages/admin/index.vue").then(m => m.default || m)
  },
  {
    name: "admin-installations-id",
    path: "installations/:id()",
    component: () => import("/opt/buildhome/repo/pages/admin/installations/[id].vue").then(m => m.default || m)
  },
  {
    name: "admin-installations-copy-id",
    path: "installations/copy/:id()",
    component: () => import("/opt/buildhome/repo/pages/admin/installations/copy/[id].vue").then(m => m.default || m)
  },
  {
    name: "admin-installations-create",
    path: "installations/create",
    component: () => import("/opt/buildhome/repo/pages/admin/installations/create.vue").then(m => m.default || m)
  },
  {
    name: "admin-installations",
    path: "installations",
    component: () => import("/opt/buildhome/repo/pages/admin/installations/index.vue").then(m => m.default || m)
  },
  {
    name: "admin-installations-reports",
    path: "installations/reports",
    component: () => import("/opt/buildhome/repo/pages/admin/installations/reports.vue").then(m => m.default || m)
  },
  {
    name: "admin-locations-id",
    path: "locations/:id()",
    component: () => import("/opt/buildhome/repo/pages/admin/locations/[id].vue").then(m => m.default || m)
  },
  {
    name: "admin-locations-create",
    path: "locations/create",
    component: () => import("/opt/buildhome/repo/pages/admin/locations/create.vue").then(m => m.default || m)
  },
  {
    name: "admin-locations",
    path: "locations",
    component: () => import("/opt/buildhome/repo/pages/admin/locations/index.vue").then(m => m.default || m)
  },
  {
    name: "admin-profile",
    path: "profile",
    component: () => import("/opt/buildhome/repo/pages/admin/profile/index.vue").then(m => m.default || m)
  },
  {
    name: "admin-shipping",
    path: "shipping",
    component: () => import("/opt/buildhome/repo/pages/admin/shipping/index.vue").then(m => m.default || m)
  },
  {
    name: "admin-users-id",
    path: "users/:id()",
    component: () => import("/opt/buildhome/repo/pages/admin/users/[id].vue").then(m => m.default || m)
  },
  {
    name: "admin-users",
    path: "users",
    component: () => import("/opt/buildhome/repo/pages/admin/users/index.vue").then(m => m.default || m)
  }
]
  },
  {
    name: "changepassword",
    path: "/changepassword",
    component: () => import("/opt/buildhome/repo/pages/changepassword.vue").then(m => m.default || m)
  },
  {
    name: "forgotpassword",
    path: "/forgotpassword",
    component: () => import("/opt/buildhome/repo/pages/forgotpassword.vue").then(m => m.default || m)
  },
  {
    name: "index",
    path: "/",
    component: () => import("/opt/buildhome/repo/pages/index.vue").then(m => m.default || m)
  },
  {
    name: installern1ozRyAz2mMeta?.name,
    path: "/installer",
    component: () => import("/opt/buildhome/repo/pages/installer.vue").then(m => m.default || m),
    children: [
  {
    name: "installer",
    path: "",
    component: () => import("/opt/buildhome/repo/pages/installer/index.vue").then(m => m.default || m)
  },
  {
    name: "installer-profile",
    path: "profile",
    component: () => import("/opt/buildhome/repo/pages/installer/profile/index.vue").then(m => m.default || m)
  },
  {
    name: "installer-task-id",
    path: "task/:id()",
    component: () => import("/opt/buildhome/repo/pages/installer/task/[id].vue").then(m => m.default || m)
  }
]
  },
  {
    name: "lib-utils",
    path: "/lib/utils",
    component: () => import("/opt/buildhome/repo/pages/lib/utils.ts").then(m => m.default || m)
  },
  {
    name: locationownerjtiVyh8TyEMeta?.name,
    path: "/locationowner",
    component: () => import("/opt/buildhome/repo/pages/locationowner.vue").then(m => m.default || m),
    children: [
  {
    name: "locationowner-ads",
    path: "ads",
    component: () => import("/opt/buildhome/repo/pages/locationowner/ads/index.vue").then(m => m.default || m)
  },
  {
    name: "locationowner-campaigns-create",
    path: "campaigns/create",
    component: () => import("/opt/buildhome/repo/pages/locationowner/campaigns/create.vue").then(m => m.default || m)
  },
  {
    name: "locationowner-campaigns",
    path: "campaigns",
    component: () => import("/opt/buildhome/repo/pages/locationowner/campaigns/index.vue").then(m => m.default || m)
  },
  {
    name: "locationowner",
    path: "",
    component: () => import("/opt/buildhome/repo/pages/locationowner/index.vue").then(m => m.default || m)
  },
  {
    name: "locationowner-locations-id",
    path: "locations/:id()",
    component: () => import("/opt/buildhome/repo/pages/locationowner/locations/[id].vue").then(m => m.default || m)
  },
  {
    name: "locationowner-locations",
    path: "locations",
    component: () => import("/opt/buildhome/repo/pages/locationowner/locations/index.vue").then(m => m.default || m)
  },
  {
    name: "locationowner-profile",
    path: "profile",
    component: () => import("/opt/buildhome/repo/pages/locationowner/profile/index.vue").then(m => m.default || m)
  }
]
  },
  {
    name: "login",
    path: "/login",
    component: () => import("/opt/buildhome/repo/pages/login.vue").then(m => m.default || m)
  },
  {
    name: operatorUDg64585YbMeta?.name,
    path: "/operator",
    component: () => import("/opt/buildhome/repo/pages/operator.vue").then(m => m.default || m),
    children: [
  {
    name: "operator-ads",
    path: "ads",
    component: () => import("/opt/buildhome/repo/pages/operator/ads/index.vue").then(m => m.default || m)
  },
  {
    name: "operator-campaigns-create",
    path: "campaigns/create",
    component: () => import("/opt/buildhome/repo/pages/operator/campaigns/create.vue").then(m => m.default || m)
  },
  {
    name: "operator-campaigns",
    path: "campaigns",
    component: () => import("/opt/buildhome/repo/pages/operator/campaigns/index.vue").then(m => m.default || m)
  },
  {
    name: "operator",
    path: "",
    component: () => import("/opt/buildhome/repo/pages/operator/index.vue").then(m => m.default || m)
  },
  {
    name: "operator-locations-id",
    path: "locations/:id()",
    component: () => import("/opt/buildhome/repo/pages/operator/locations/[id].vue").then(m => m.default || m)
  },
  {
    name: "operator-locations",
    path: "locations",
    component: () => import("/opt/buildhome/repo/pages/operator/locations/index.vue").then(m => m.default || m)
  },
  {
    name: "operator-profile",
    path: "profile",
    component: () => import("/opt/buildhome/repo/pages/operator/profile/index.vue").then(m => m.default || m)
  }
]
  },
  {
    name: "register",
    path: "/register",
    component: () => import("/opt/buildhome/repo/pages/register.vue").then(m => m.default || m)
  },
  {
    name: "resetpassword-id",
    path: "/resetpassword/:id()",
    component: () => import("/opt/buildhome/repo/pages/resetpassword/[id].vue").then(m => m.default || m)
  },
  {
    name: "resetpassword",
    path: "/resetpassword",
    component: () => import("/opt/buildhome/repo/pages/resetpassword/index.vue").then(m => m.default || m)
  }
]